import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../components/projectCard";
import Spinner from "../../../commons/components/spinner";
import { projectsCurrentTabContext } from "../context/projectsContext";
import ProjectsMenuTabs from "../components/projectsMenuTabs";
import { urls, commonConfigs } from "../../../config/config";
import ComingSoonModal from "../../../commons/components/comingSoonModal";

const ProjectLayout = () => {
  const { currentTab } = useContext(projectsCurrentTabContext);
  const [isOpen, setOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [stagingData, setStagingData] = useState(null);
  const [productionData, setProductionData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const ssoId = localStorage.getItem("ssoId");

  const getAllStagingDeveloperProjectsUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.GetAllDeveloperProjects(ssoId, "staging");
  const getAllProductionDeveloperProjectsUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.GetAllDeveloperProjects(ssoId, "production");
  const presetOptions = { environment: { value: "1", label: "Production" } };

  const handleNavigateToPricingPlan = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    navigate("/subscriptions/pricing-plan", { state: { tabValue: 0 } });
  };

  const handleNavigateToProdPricingPlan = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    navigate("/subscriptions/pricing-plan", { state: { tabValue: 2 } });
  };

  async function getStagingDeveloperProjects() {
    await axios
      .get(getAllStagingDeveloperProjectsUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        setStagingData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getProductionDeveloperProjects() {
    await axios
      .get(getAllProductionDeveloperProjectsUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        setProductionData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getStagingDeveloperProjects();
    getProductionDeveloperProjects();
  }, []);

  const onHandleGetStarted = () => {
    setOpen(true);
    // const disableButton = productionData.some(project => project.developer_proj_agreement !== 1);
    // if (disableButton) {
    //   setIsDisabled(disableButton);
    //   alert('You have not signed the agreement!');
    // } else {
    //   navigate('/projects/create', {
    //     state: { presetEnvironment: { value: "1", label: "Production" }, isProd: 1 },
    //   })
    // }
  };

  if (!load) {
    return (
      <div style={{ width: "100%" }}>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          {/* button for 'Create Project' */}
          <button
            type="button"
            onClick={onHandleGetStarted}
            className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2.5 px-14 rounded-full mr-10"
            style={{
              float: "right",
              visibility:
                currentTab.name === "Production" ? "visible" : "hidden",
            }}
          >
            Create New Project
          </button>

          {isOpen && <ComingSoonModal setOpenModal={setOpen} />}
          <div>
            <div
              className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2"
              style={{ marginBottom: "-20px" }}
            >
              <ProjectsMenuTabs />
            </div>
            {currentTab.name == "Staging" && (
              <>
                {stagingData != null ? (
                  <>
                    {stagingData.length > 0 ? (
                      <div
                        className="mt-10 flex flex-grow gap-x-2 justify-end pr-10"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 2fr 2fr",
                        }}
                      >
                        {stagingData.map((item, index) => (
                          <Card key={index} data={item} environment="staging" />
                        ))}
                      </div>
                    ) : (
                      <>
                        <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                          <div className="py-16">
                            <div className="text-center">
                              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                You don't have any active projects yet!
                              </h1>
                            </div>
                            <div className="mt-6">
                              <Link
                                to="#"
                                onClick={handleNavigateToPricingPlan}
                                className="text-base text-xl font-medium text-gray-400 hover:text-gray-500"
                              >
                                To get started, check out our Pricing Plans and
                                create your first project!
                                <span aria-hidden="true"> &rarr;</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                    <div className="flex place-content-center w-full">
                      <Spinner className="w-20 h-20" />
                    </div>
                  </div>
                )}
              </>
            )}
            {currentTab.name == "Production" && (
              <>
                {/* {productionData != null ? (
                  <>
                    {productionData.length > 0 ? (
                      <div
                        className="mt-10 flex flex-grow gap-x-2 justify-end pr-10"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 2fr 2fr",
                        }}
                      >
                        {productionData.map((item, index) => (
                          <Card key={index} data={item} environment="production" />
                        ))}
                      </div>
                    ) : (
                      <>
                        <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                          <div className="py-16">
                            <div className="text-center">
                              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                You don't have any production projects yet!
                              </h1>
                            </div>
                            <div className="mt-6">
                              <Link
                                to="#"
                                onClick={handleNavigateToProdPricingPlan}
                                className="text-base text-xl font-medium text-gray-400 hover:text-gray-500"
                              >
                                To get started, check out our Pricing Plans and
                                create your first project!
                                <span aria-hidden="true"> &rarr;</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                    <div className="flex place-content-center w-full">
                      <Spinner className="w-20 h-20" />
                    </div>
                  </div>
                )} */}
                <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                  <div className="py-16">
                    <div className="text-center">
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Coming Soon
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ProjectLayout;
