import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ComingSoonModal from "../../../../commons/components/comingSoonModal";
import { commonConfigs, urls } from "../../../../config/config";
import SubscriptionMenuTabs from "../../components/subscriptionMenuTabs";
import { subscriptionCurrentTabContext } from "../../context/subscriptionContext";
import CertificationLayout from "../components/certification/certificationPlanLayout";
import ProductionPricingPlanLayout from "../components/production/productionPlanLayout";

const PricingPlanLayout = () => {
  const [load, setLoad] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [comingSoonDate, setComingSoonDate] = useState(null);
  const { currentTab } = useContext(subscriptionCurrentTabContext);

  const handleComingSoonClick = (date) => {
    setComingSoonDate(date);
    setOpen(true);
  };

  if (!load) {
    return (
      <div>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          <div style={{ display: "inline-flex", float: "right" }}>
            <Link to="/invoices">
              <button
                onClick={() => handleComingSoonClick()}
                type="button"
                className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full mr-6"
                style={{ float: "right" }}
              >
                Invoices
              </button>
            </Link>
            <Link to="/subscriptions">
              <button
                type="button"
                className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold py-2 px-12 rounded-full mr-6"
                style={{ float: "right" }}
              >
                Subscriptions
              </button>
            </Link>
          </div>
          {/* calling the Card component */}
          <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2 sm:ml-5">
            <div
              className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2"
              style={{ marginBottom: "-20px" }}
            >
              <SubscriptionMenuTabs />
            </div>
            {currentTab.name == "Staging" && (
              // <div
              //   className="mt-8 flex flex-grow gap-x-5 justify-end pr-10"
              //   style={{
              //     display: "grid",
              //     gridTemplateColumns: "1fr 1fr 1fr 1fr",
              //     marginLeft: "5px",
              //   }}
              // >
              //   <SandboxPlan />
              // </div>
              <>
                <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                  <div className="py-16">
                    <div className="text-center">
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Coming Soon
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentTab.name == "Production" && (
              <>
                <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                  <div className="py-16">
                    <div className="text-center">
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Coming Soon
                      </h1>
                    </div>
                  </div>
                </div>
                {/* <div className="mr-5">
                <ProductionPricingPlanLayout />
              </div> */}
              </>
            )}
            {currentTab.name == "Tech Support" && (
              <>
                <div className="min-h-screen pt-16 pb-12 flex flex-col ">
                  <div className="py-16">
                    <div className="text-center">
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Coming Soon
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentTab.name == "Certification" && (
              <>
                <CertificationLayout currentTab={currentTab} />
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <ComingSoonModal
            setOpenModal={setOpen}
            comingSoonDate={comingSoonDate}
          />
        )}
      </div>
    );
  }
};

export default PricingPlanLayout;
